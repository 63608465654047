import { Layout } from "antd";
import React, { lazy, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Switch } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { MobileNav } from "../components/nav/MobileNav";
import NavDrawer from "../components/nav/SideNav";
import PageFooter from "../components/PageFooter";
import PageError from "../pages/PageError";
import Home from "./../pages/home/Home";

const { Content } = Layout;

// Lazy imports for slight performance boost
const ArticlePage = lazy(() =>
  import("../pages/research/articles/ArticlePage")
);
const NewsPage = lazy(() => import("../pages/news/NewsPage"));
const Research = lazy(() => import("../pages/research/Research"));
const Careers = lazy(() => import("../pages/careers/Careers"));
const Services = lazy(() => import("../pages/technology/Technology"));
const Products = lazy(() => import("../pages/products/Product"));
const NordicIce = lazy(() => import("../pages/products/product/NordicIce"));
const Publications = lazy(() => import("../pages/publications/NewsPage"));

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ParallaxProvider>
        <Layout style={{ minHeight: "100vh" }}>
          <MobileNav /> <NavDrawer id="navDrawer" />
          <Layout className="site-layout" id="bodyWrapper">
            <Content>
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route exact path={["/", "/home"]} component={Home} />
                  <Route path="/news/article/:id" component={ArticlePage} />
                  <Route path="/news" component={NewsPage} />
                  <Route path="/research/article/:id" component={ArticlePage} />
                  <Route path="/research" component={Research} />
                  <Route path="/careers" component={Careers} />
                  <Route path="/technology" component={Services} />
                  <Route path="/publications" component={Publications} />
                  <Route path="/products" component={Products} />
                  <Route path="/product/nordicice" component={NordicIce} />
                  <Route path="*" component={PageError} />
                </Switch>
              </Suspense>
            </Content>
            <PageFooter />
          </Layout>
        </Layout>
      </ParallaxProvider>
    </QueryClientProvider>
  );
}
