import { Col, Row } from "antd";
import React, { useState } from "react";
import { ChevronDown } from "react-feather";
import { InView } from "react-intersection-observer";
import Particles from "react-particles-js";
import Typist from "react-typist";
import { AnimatedPresence } from "../../components/AnimatedPresence";
import ContentSection from "../../components/ContentSection";
import { windowWidth } from "../../hooks/useWindowWidth";

export const HeadingSection = () => {
  const [width] = windowWidth();
  return (
    <Row>
      <Col xs={0} lg={24}>
        <ContentSection
          className="gradient"
          height="100vh"
          padding="0"
          left={
            <>
              <SectionBody />{" "}
              {width > 576 ? (
                <AnimatedPresence
                  transition={{ duration: 2, delay: 0 }}
                  variants={{
                    visible: { opacity: 0.6 },
                    hidden: { opacity: 0 },
                  }}
                >
                  <Particles
                    style={{ width: "100%", height: "100%", zIndex: 0 }}
                  />
                </AnimatedPresence>
              ) : null}
            </>
          }
        />
      </Col>
      <Col xs={24} lg={0}>
        <ContentSection
          className="gradient"
          height="60vh"
          padding="0"
          left={<SectionBody />}
        />
      </Col>
    </Row>
  );
};

const SectionBody = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <InView
        as="div"
        onChange={(inView) => inView && setShow(inView)}
        style={{
          maxWidth: "650px",
          minWidth: "360px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1,
        }}
      >
        {show && (
          <>
            <Typist
              startDelay={400}
              avgTypingDelay={40}
              stdTypingDelay={10}
              cursor={{
                show: false,
              }}
            >
              <div>
                <h1
                  className="text-xxlarge text-white"
                  style={{
                    textAlign: "center",
                  }}
                >
                  [ Radiology Powered by <span className="text-blue">AI</span> ]
                </h1>
              </div>
            </Typist>
            <AnimatedPresence transition={{ delay: 1.5 }}>
              <div
                className="text-faded text-medium"
                style={{ textAlign: "center" }}
              >
                We develop deep learning-based methods in medical imaging
                diagnostics, clinical deployment of AI applications and database
                development optimized for machine learning applied to big
                medical data.
              </div>
            </AnimatedPresence>
            <AnimatedPresence transition={{ delay: 1.7 }}>
              <ChevronDown
                className="bounce text-white"
                style={{
                  position: "absolute",
                  bottom: "-100px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              />
            </AnimatedPresence>
          </>
        )}
      </InView>
    </>
  );
};
