import React, { useLayoutEffect } from "react";
import {
  Parallax as ScrollParallax,
  useController,
} from "react-scroll-parallax";
import PropTypes from "prop-types";

const Parallax = ({ children, xoffset = [0, 0], yoffset = [-20, 20] }) => {
  return (
    <>
      <ScrollParallax
        className="custom-class"
        y={yoffset}
        x={xoffset}
        tagOuter="figure"
      >
        {children}
      </ScrollParallax>
    </>
  );
};

Parallax.propTypes = {
  xoffset: PropTypes.array,
  yoffset: PropTypes.array,
  children: PropTypes.any,
};

export default Parallax;

// To fix parallax stuck issue after page reload
export const ParallaxCache = () => {
  const { parallaxController } = useController();

  useLayoutEffect(() => {
    const handler = () => parallaxController.update();
    window.addEventListener("load", handler);
    return () => window.removeEventListener("load", handler);
  }, [parallaxController]);

  return null;
};
