import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useScrollSections } from "react-scroll-section";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Col, Row } from "antd";

const AnchorMenu = ({ input = [0, 0.1, 0.23], output = [0, 0, 1] }) => {
  // Hides menu until scrolled down on page
  const { scrollYProgress } = useViewportScroll();
  const opacity = useTransform(scrollYProgress, input, output);
  const [scroll, setScoll] = useState(0);

  useEffect(() => {
    return scrollYProgress.onChange((y) => {
      setScoll(y);
    });
  }, [scrollYProgress]);

  const sections = useScrollSections();

  const menuStyle = {
    display: scroll > 0.2 ? "block" : "none",
    zIndex: 1,
    top: "20vh",
    transform: "translateY(-50%)",
    right: "30px",
    position: "fixed",
    color: "var(--white)",
    opacity: opacity,
  };

  const itemStyle = (selected) => {
    return {
      width: "90px",
      paddingLeft: "10px",
      listStyleType: "none",
      cursor: "pointer",
      transition: "all 0.25s ease",
      margin: "0",
      fontWeight: "inherit",
      fontSize: "16px",
      userSelect: "none",
      color: selected ? "white" : "rgba(255,255,255,0.3)",
      borderLeft: selected ? "2px solid white" : "none",
    };
  };

  return (
    <motion.div style={menuStyle}>
      <Row>
        <Col xs={0} lg={24}>
          <ul>
            {sections.map(({ id, onClick, selected }) => (
              <li
                key={id}
                onClick={onClick}
                selected={selected}
                style={itemStyle(selected)}
              >
                {id.toUpperCase()}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </motion.div>
  );
};

AnchorMenu.propTypes = {
  input: PropTypes.array,
  output: PropTypes.array,
};

export default AnchorMenu;
