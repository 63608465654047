import React from "react";
import { Carousel as AntdCarousel } from "antd";
import { ChevronLeft, ChevronRight } from "react-feather";
import PropTypes from "prop-types";

export const Carousel = ({
  arrows = true,
  dotPosition = "bottom",
  autoplay = true,
  autoplaySpeed = 7000,
  pauseOnHover = false,
  swipeToSlide,
  slidesToShow = 1,
  slidesToScroll = 1,
  rows = 1,
  responsive = null,
  lazyLoad = true,
  effect = "fade",
  className = "background-dark",
  style,
  children,
}) => {
  return (
    <AntdCarousel
      effect={effect}
      arrows={arrows}
      dotPosition={dotPosition}
      autoplay={autoplay}
      autoplaySpeed={autoplaySpeed}
      pauseOnHover={pauseOnHover}
      swipeToSlide={swipeToSlide}
      slidesToShow={slidesToShow}
      slidesToScroll={slidesToScroll}
      rows={rows}
      responsive={responsive}
      pauseOnDotsHover={true}
      prevArrow={<ChevronLeft />}
      nextArrow={<ChevronRight />}
      className={className}
      lazyLoad={lazyLoad}
      cssEase="ease-in-out"
      style={{
        ...style,
      }}
    >
      {children}
    </AntdCarousel>
  );
};

Carousel.propTypes = {
  arrows: PropTypes.bool,
  dotPosition: PropTypes.string,
  autoplay: PropTypes.bool,
  autoplaySpeed: PropTypes.number,
  pauseOnHover: PropTypes.bool,
  swipeToSlide: PropTypes.bool,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  rows: PropTypes.number,
  responsive: PropTypes.array,
  lazyLoad: PropTypes.bool,
  effect: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any,
};
