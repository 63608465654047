import { FileDoneOutlined } from "@ant-design/icons";
import { Avatar, Card, Space, Typography } from "antd";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ArrowRight } from "react-feather";
import { dateFormatter } from "../../dateFormatter";
import { categoriesToSting } from "./articles/Article";

const { Paragraph } = Typography;

const publicationButtonStyle = {
  position: "absolute",
  right: "24px",
  bottom: "24px",
  borderRadius: "100px",
  padding: "8px",
  border: "2px solid var(--dark-light)",
  lineHeight: 0,
};

const ResearchCard = ({ title, author, date, style, categories }) => {
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    setCategoryList(categoriesToSting(categories));
  }, []);

  return (
    <motion.div
      whileHover={{ scale: 1, y: -12, zIndex: 5 }}
      whileTap={{ scale: 0.9 }}
      className="rounded shadow-medium pointer"
    >
      <Card
        className="background text-white rounded"
        loading={false}
        bordered={false}
        style={{
          border: "2px solid var(--dark-light)",
          minHeight: "400px",
          ...style,
        }}
      >
        <Space
          align="center"
          className="text-blue"
          style={{ marginBottom: "20px" }}
        >
          <Avatar
            size="medium"
            className="background-dark text-blue"
            icon={<FileDoneOutlined />}
          />
          <span>{categoryList}</span>
        </Space>
        <Paragraph
          className="text-white text-semi-large"
          ellipsis={{ rows: 5 }}
        >
          {title}
        </Paragraph>
        <Paragraph className="text-small text-faded" ellipsis={{ rows: 3 }}>
          {author}
        </Paragraph>
        <p className="text-small text-faded">{dateFormatter(date)}</p>

        <div style={publicationButtonStyle}>
          <ArrowRight color="var(--blue)" />
        </div>
      </Card>
    </motion.div>
  );
};

ResearchCard.propTypes = {
  title: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
  categories: PropTypes.array,
  style: PropTypes.object,
};

export default ResearchCard;
