import React from "react";
import { ScrollingProvider, Section } from "react-scroll-section";
import AnchorMenu from "../../components/AnchorMenu";
import ScrollToTop from "../../components/ScrollToTop";
// images
import { AboutSection } from "./AboutSection";
import { FooterCards } from "./footerCards/FooterCards";
import { HeadingSection } from "./HeadingSection";
import LandingSection from "./landingSection/LandingSection";
import { MissionSection } from "./MissionSection";
import { PartnerSection } from "./PartnerSection";
import { TeamSection } from "./team/TeamSection";

export default function Home() {
  return (
    <>
      <div>
        <ScrollToTop />
        <ScrollingProvider>
          <LandingSection />
          <AnchorMenu />
          <PartnerSection />
          <Section id="About">
            <HeadingSection />
            <AboutSection />
          </Section>
          <Section id="Mission">
            <MissionSection />
          </Section>
          <Section id="The Team">
            <TeamSection />
          </Section>
          <FooterCards />
        </ScrollingProvider>
      </div>
    </>
  );
}
