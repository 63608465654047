import { Col, Row } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { fetchAllEmployees } from "../../../api/Api";
import { AnimatedPresence } from "../../../components/AnimatedPresence";
import SwipeTrack from "../../../components/SwipeTrack";
import PageError from "../../PageError";
import { Member } from "./Member";
import { MemberCarousel } from "./MemberCarousel";

export const TeamSection = () => {
  const {
    isLoading,
    error,
    data: allEmployees,
  } = useQuery("employees", fetchAllEmployees, {
    refetchOnWindowFocus: false,
  });

  if (isLoading) return <div>Loading...</div>;

  if (error)
    return (
      <PageError
        code="500"
        message="Uh oh! Something went wrong while trying to find this content"
      />
    );

  return (
    <Row
      className="background"
      justify="center"
      align="middle"
      gutter={[32, 16]}
      style={{ padding: "15vh 0" }}
    >
      <Col xs={24}>
        <Row justify="center">
          <Col style={{ maxWidth: "600px" }}>
            <AnimatedPresence>
              <h1
                className="text-xlarge text-white"
                style={{ textAlign: "center" }}
              >
                MEET THE TEAM
              </h1>
            </AnimatedPresence>
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Row justify="center">
          <Col style={{ maxWidth: "600px" }}>
            <div style={{ padding: "0 24px" }}>
              <AnimatedPresence>
                <p
                  className="text-medium text-faded"
                  style={{ textAlign: "center" }}
                >
                  We&apos;re a motivated and dedicated group of scientists,
                  software engineers, medical doctors, and PhD/Masters students
                  with a common interest in diagnostic imaging and machine
                  learning.
                  <br />
                  <div style={{ marginTop: "10px" }}>
                    {/*                     <Link to="/careers">
                      <ViewMore text="Apply here" arrowRight />
                    </Link> */}
                  </div>
                </p>
              </AnimatedPresence>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={0} md={20} lg={18} xlg={14}>
        <MemberCarousel employees={allEmployees} />
      </Col>
      <Col xs={24} md={0}>
        <SwipeTrack
          height="140px"
          grid={`1fr/repeat(${allEmployees.length},100px)`}
        >
          {allEmployees.map((member) => (
            <Member key={member.name} member={member} mobileView />
          ))}
        </SwipeTrack>
      </Col>
    </Row>
  );
};
