import { Space } from "antd";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";
import { ArrowRight } from "react-feather";
import { Link } from "react-router-dom";

export const FooterCard = ({
  title,
  body,
  link,
  target_value,
  height = "auto",
  minHeight = "auto",
}) => {
  const style = {
    width: "100%",
    padding: "40px",
    border: "var(--border)",
    height: height,
    minHeight: minHeight,
  };
  return (
    <Link 
    to={{pathname:`${link}`}} target={target_value}>
      <motion.div
        style={style}
        className="wave-bg-fixed rounded pointer"
        whileHover={{ scale: 1.06 }}
        whileTap={{ scale: 0.9 }}
      >
        <Space>
          <h1 className="text-purple text-medium">{title}</h1>
          <ArrowRight className="text-purple" />
        </Space>
        <p className="text-small text-faded">{body}</p>
      </motion.div>
    </Link>
  );
};
FooterCard.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  link: PropTypes.string,
  target_value:PropTypes.string,
  height: PropTypes.string,
  minHeight: PropTypes.string,
};
