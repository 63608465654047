import { useLayoutEffect, useState } from "react";

export function windowWidth() {
  const [width, setWidth] = useState([0]);
  useLayoutEffect(() => {
    function updateSize() {
      setWidth([window.innerWidth]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return width;
}

export const breakpoints = { large: 1280, medium: 900, small: 740 };
