import { Col } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { fetchAllNews, fetchAllResearchArticles } from "../../../api/Api";
import { AnimatedPresence } from "../../../components/AnimatedPresence";
import ResearchCard from "../ResearchCard";

// Show random posts as recommended
function shuffleArray(array) {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

const Articles = ({ slug, type = "research" }) => {
  console.log(type);
  const {
    isLoading,
    error,
    data: allArticles,
  } = useQuery(
    type === "research" ? "articles" : "news",
    type === "research" ? fetchAllResearchArticles : fetchAllNews,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>Error...</div>;

  const recommended = shuffleArray(allArticles)
    .filter((article) => article.slug.current !== slug)
    .slice(0, 3);

  function getArticles() {
    if (slug) {
      return recommended;
    }
    return allArticles;
  }

  return getArticles().map(
    ({ index, title, slug, name, publishedAt, categories }) => (
      <Col key={slug.current} xs={24} md={12} lg={8}>
        <Link
          to={
            type === "research"
              ? `/research/article/${slug.current}`
              : `/news/article/${slug.current}`
          }
        >
          <AnimatedPresence
            transition={{
              // Random delay for organic behaviour
              delay: index !== 0 ? 0 + Math.random() * (0.4 - 0) : 0,
            }}
            options={{ treshold: 0.8, delay: 100 }}
          >
            <ResearchCard
              title={title}
              author={name}
              date={publishedAt}
              categories={categories}
            />
          </AnimatedPresence>
        </Link>
      </Col>
    )
  );
};

export default Articles;

Articles.propTypes = {
  slug: PropTypes.any,
  type: PropTypes.string,
};
