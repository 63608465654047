import { Col, Row } from "antd";
import React from "react";
import Container from "../../../components/Container";
import TextSection from "../../../components/TextSection";
import { FooterCard } from "./FooterCard";

export const FooterCards = () => {
  return (
    <div className="background-dark" style={{ paddingBottom: "15vh" }}>
      <Container>
        <TextSection
          color="text-purple"
          header={<div className="text-large">NEXT UP</div>}
          body={""}
        />
        <br />
        <Row gutter={[32, 32]}>
          <Col xs={24} md={12} lg={8}>
            <FooterCard
              minHeight="215px"
              title={"Research"}
              body={
                "We're aspiration to answer important questions in the field of medtech"
              }
              link={"research"}
            />
          </Col>
          <Col xs={24} md={12} lg={8}>
            <FooterCard
              minHeight="215px"
              title={"NeoMedSys"}
              body={
                "Systems and platforms that brings hospitals into the digital age"
              }
              link="https://neomedsys.io/" 
              target_value='_blank'
            />
          </Col>
          <Col xs={24} md={12} lg={8}>
            <FooterCard
              minHeight="215px"
              title={"Careers"}
              body={
                "Apply for one of our vacant positions and join our mission"
              }
              link={"careers"}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
