import React from "react";
import PropTypes from "prop-types";
import Container from "./Container";
import { Col, Row } from "antd";

const ContentSection = ({
  left,
  right,
  className = "background-dark",
  padding = "15vh 0",
  height = "auto",
  switchMobileOrder = false,
}) => {
  if (left && right)
    return (
      <div
        style={{
          height: height,
          padding: padding,
          borderTop: "var(--border)",
          borderBottom: "var(--border)",
        }}
        className={className}
      >
        <Container>
          <Row
            justify="center"
            align="middle"
            gutter={[42, 16]}
            style={{ width: "100%", marginBottom: 0 }}
          >
            <Col
              md={{ span: 24, order: switchMobileOrder ? 2 : 1 }}
              lg={{ span: 12, order: 1 }}
            >
              {left}
            </Col>
            <Col
              md={{ span: 24, order: switchMobileOrder ? 1 : 2 }}
              lg={{ span: 12, order: 2 }}
            >
              {right}
            </Col>
          </Row>
        </Container>
      </div>
    );

  return (
    <div
      style={{
        height: height,
        padding: padding,
        position: "relative",
      }}
      className={className}
    >
      <Row justify="center" align="middle" style={{ height: "100%" }}>
        <Col span={21}>
          <Row
            justify="center"
            align="middle"
            gutter={[42, 16]}
            style={{ height: "100%" }}
          >
            <Col span={24}>{left ? left : right}</Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

ContentSection.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  className: PropTypes.string,
  padding: PropTypes.string,
  height: PropTypes.string,
  switchMobileOrder: PropTypes.bool,
};

export default ContentSection;
