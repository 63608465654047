import moment from "moment";
import React from "react";

export const dateFormatter = (date) => {
  let formattedDate = moment(date);
  let month = formattedDate.format("MMM");
  let day = formattedDate.format("DD");
  let year = formattedDate.year();

  return (
    <>
      {month + ` ` + day}
      <span>{", " + year}</span>
    </>
  );
};
