import PropTypes from "prop-types";
import React from "react";
import { Carousel } from "../../../components/Carousel";
import { Member } from "./Member";

export const MemberCarousel = ({ employees }) => {
  return (
    <Carousel
      className="background"
      style={{ paddingBottom: "50px" }}
      rows={3}
      arrows={false}
      autoplay={false}
      pauseOnHover={true}
      slidesToShow={8}
      slidesToScroll={7}
      effect=""
      responsive={[
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 550,
          settings: {
            rows: 2,
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ]}
    >
      {employees.map((member) => (
        <Member key={member.name} member={member} />
      ))}
    </Carousel>
  );
};

MemberCarousel.propTypes = {
  employees: PropTypes.array,
};
