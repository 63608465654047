import { Space } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { ArrowDown, ArrowRight } from "react-feather";

export const ViewMore = ({
  text,
  arrowRight = false,
  className = "view-more",
  icon = <ArrowDown size={20} style={{ marginTop: "10px" }} />,
}) => {
  return (
    <Space className={className}>
      <span className="text-medium" style={{ margin: 0 }}>
        {text}
      </span>
      {arrowRight ? (
        <ArrowRight size={20} style={{ marginTop: "10px" }} />
      ) : (
        icon
      )}
    </Space>
  );
};

ViewMore.propTypes = {
  text: PropTypes.string.isRequired,
  arrowRight: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.node,
};
