import { PortableText } from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";
import { Col, Row } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchOneNewsArticle, fetchOneResearchArticle } from "../../../api/Api";
import sanityClient from "../../../api/Client";
import Container from "../../../components/Container";
import ScrollToTop from "../../../components/ScrollToTop";
import Article from "./Article";
import Articles from "./Articles";

const builder = imageUrlBuilder(sanityClient);

export function urlFor(source) {
  return builder.image(source);
}

// Customized images in body
export const bodyImage = {
  types: {
    image: ({ value }) => {
      return (
        <Row justify="center" align="middle" style={{ marginBottom: "20px" }}>
          <Col>
            <div
              className="rounded-lg"
              style={{
                overflow: "hidden",
                maxWidth: "100%",
                maxHeight: "1000px",
              }}
            >
              <img
                className="rounded-lg"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
                src={urlFor(value).url()}
                alt={value.asset.alt || " "}
                title={value.asset.title}
                loading="lazy"
              />
              <div
                style={{ margin: "14px 0px 28px 0px", opacity: 0.7 }}
                className="text-small"
              >
                <span>{value.asset.description || ""}</span>
              </div>
            </div>
          </Col>
        </Row>
      );
    },
  },
};

export default function ArticlePage() {
  // Find article by url param
  const { id } = useParams();

  const url = window.location.pathname;

  const { isLoading, error, data } = useQuery(
    [url.includes("/research/article/") ? "article" : "newsArticle", id],
    () =>
      url.includes("/research/article/")
        ? fetchOneResearchArticle(id)
        : fetchOneNewsArticle(id),
    { refetchOnWindowFocus: false }
  );

  if (isLoading) return <div></div>;

  if (error) return <div>Error</div>;

  // Filters the right article depending on current url
  return (
    <>
      <ScrollToTop />
      <div className="background text-faded">
        <Article
          slug={data.slug.current}
          categories={data.categories}
          title={data.title}
          mainImage={data.mainImage}
          body={
            <PortableText
              value={data.body}
              components={bodyImage}
              projectId={sanityClient.config().projectId}
              dataset={sanityClient.config().dataset}
            />
          }
          publishedAt={data.publishedAt}
          updatedAt={data.updatedAt}
          author={{
            name: data.name,
            projects: data.authorProjects,
            image: data.authorImage,
            bio: (
              <PortableText
                value={data.authorBio}
                projectId={sanityClient.config().projectId}
                dataset={sanityClient.config().dataset}
              />
            ),
          }}
        />
        <Container>
          <Row
            gutter={[32, 32]}
            style={{
              paddingBottom: "20vh",
              paddingTop: "40px",
              borderTop: "var(--border)",
            }}
          >
            <Col xs={24}>
              <h1 className="text-large text-white">Recommended</h1>
            </Col>
            <Col lg={24}>
              <Row gutter={[32, 32]}>
                <Articles
                  slug={id}
                  type={
                    url.includes("/research/article/") ? "research" : "news"
                  }
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
