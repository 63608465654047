import { Col, Row } from "antd";
import React from "react";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import Container from "../../components/Container";
import TextSection from "../../components/TextSection";
import gjengen from "../../images/gjengen.webp";

export const AboutSection = () => (
  <div className="background">
    <Container>
      <Row
        align="middle"
        justify="center"
        style={{ padding: "60px 0" }}
        gutter={[64, 64]}
      >
        <Col xs={24}>
          <TextSection
            brow={"ABOUT"}
            header={"WHO WE ARE"}
            body={
              <Row gutter={[64, 34]}>
                <Col xs={24} lg={12}>
                  The ongoing paradigm shift caused by AI-based technology at
                  scale is predicted to have a major impact on radiology by
                  reducing radiologist workload, improving diagnostic
                  performance and thereby patient care. CRAI was established in
                  2019 in response to emerging opportunities and challenges
                  posed by the introduction of AI in diagnostic imaging. The
                  group focuses on medical imaging diagnostics,
                  <Link className="view-more" to="/services#MedQuery">
                    {" "}
                    clinical AI applications{" "}
                  </Link>
                  and{" "}
                  <Link className="view-more" to="/services#MLFactory">
                    database development{" "}
                  </Link>
                  optimized for deep learning model training and deployment.
                </Col>
                <Col xs={24} lg={12}>
                  CRAI is a research hub within the Division of Radiology and
                  Nuclear Medicine at Oslo University Hospital, with group
                  members spanning a wide range of disciplines and research
                  areas but with a common interest in using machine learning
                  methods to provide better medical diagnostics solutions.
                </Col>
              </Row>
            }
          />
        </Col>
        <Col xs={24} style={{ marginTop: "32px" }}>
          <LazyLoad once offset={50}>
            <img
              src={gjengen}
              style={{
                width: "100%",
                maxHeight: "400px",
                overflow: "hidden",
                objectFit: "cover",
              }}
              className="rounded shadow-medium"
            />
          </LazyLoad>
        </Col>
      </Row>
    </Container>
  </div>
);
