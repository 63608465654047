export const PageRoutes = [
  {
    page: "Home",
    link: "/home",
    sections: [{ id: "About" }, { id: "Mission" }, { id: "Team" }],
  },
  {
    page: "Research",
    link: "/research",
    sections: [{ id: "Projects" }],
  },
  {
    page: "Publications",
    link: "/publications",
    sections: [{ id: "publications" }],
  },
  {
    page: "Products",
    link: "/products",
    sections: [{ id: "Apply" }],
  }, 
  {
    page: "News",
    link: "/news",
    sections: [{ id: "Latest" }],
  },
  {
    page: "Careers",
    link: "/careers",
    sections: [{ id: "Apply" }],
  },
];
