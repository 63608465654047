import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Container from "../components/Container";
import { PrimaryBtn } from "../components/PrimaryBtn";

export default function PageError({
  code = "404",
  message = "Uh oh! Could not find what you were looking for",
}) {
  return (
    <Container>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "90vh",
          width: "100%",
        }}
      >
        <Col>
          <h1
            className="text-white text-large"
            style={{ textAlign: "center", maxWidth: "600px" }}
          >
            <span className="text-xxlarge text-blue">{code}</span>
            <br /> <br />
            {message}
          </h1>
          <br />
          <div
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Link to="/">
              <PrimaryBtn arrowRight={true} bounce={false}>
                Return
              </PrimaryBtn>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

PageError.propTypes = {
  code: PropTypes.string,
  message: PropTypes.string,
};
