import { motion, useAnimation } from "framer-motion";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const defaultTransition = {
  duration: 0.3,
  type: "spring",
  damping: 9,
  mass: 0.6,
  stiffness: 40,
};

const defaultVariants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: 70 },
};

const defaultOptions = {
  threshold: 1,
  delay: 100,
};

export const AnimatedPresence = ({
  children,
  transition = defaultTransition,
  variants = defaultVariants,
  options = defaultOptions,
  animate = true,
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView(options);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  if (!animate) return children;

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={transition}
      variants={variants}
    >
      {children}
    </motion.div>
  );
};

AnimatedPresence.propTypes = {
  children: PropTypes.node.isRequired,
  transition: PropTypes.object,
  variants: PropTypes.object,
  options: PropTypes.object,
  animate: PropTypes.bool,
};
