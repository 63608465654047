import { Space } from "antd";
import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import "../../css/global-styles.css";
import logo from "../../images/crai_logo_mb.svg";
import ous from "../../images/partners/OUS_logo_HVIT_eng.png";
import MobileDrawer from "./MobileSideNav";

export const MobileNav = () => (
  <>
    <div style={{ height: "68px" }} className="mobile-nav" />
    <div
      className="mobile-nav"
      style={{
        height: "68px",
        width: "100vw",
        position: "fixed",
        backgroundColor: "var(--dark-default)",
        zIndex: 9998,
      }}
    >
      <div style={{ position: "relative", height: "68px" }}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "24px",
            transform: "translateY(-50%)",
          }}
        >
          <Space size={20} align="center">
            <Link to={"/"}>
              <img
                src={logo}
                height="30"
                style={{
                  zIndex: 9999,
                }}
              />
            </Link>
            <div
              className="rounded"
              style={{
                height: "20px",
                width: "1px",
                backgroundColor: "var(--faded-white)",
              }}
            ></div>
            <a
              href="https://oslo-universitetssykehus.no/oslo-university-hospital"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ous} height="20" />
            </a>
          </Space>
        </div>
      </div>
      <MobileDrawer />
    </div>
  </>
);
