import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { partners } from "../../api/PartnerList";
import { Carousel } from "../../components/Carousel";
import Container from "../../components/Container";
import { breakpoints, windowWidth } from "../../hooks/useWindowWidth";

const Partner = ({ name, img }) => {
  return (
    <div className="partner-logo-wrapper">
      <img
        src={img}
        style={{
          width: "100%",
          filter: `grayscale(100%)  ${
            name === "HELSE SØR-ØST" ? "brightness(300%)" : ""
          }`,
          paddingTop: name === "HELSE SØR-ØST" ? "20px" : "0px", // Hardcoded padding for certain logos
        }}
      />
    </div>
  );
};

const Partners = ({ partners }) => {
  return (
    <Row justify="center">
      <Col span={24} style={{ margin: "0px", padding: "0px" }}>
        <Row>
          {partners.map((partner) => (
            <Col
              key={partner.name}
              xs={12}
              sm={8}
              md={6}
              xl={partner.name === "Akershus universitetssykehus" ? 8 : 4}
            >
              <Partner name={partner.name} img={partner.img} />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export const PartnerSection = () => {
  const [width] = windowWidth();

  if (width < breakpoints.large) {
    return (
      <div
        className="background"
        style={{ borderTop: "var(--border)", borderBottom: "var(--border)" }}
      >
        <Container style={{ padding: "60px 21px 40px 21px" }}>
          <Carousel
            className="background"
            rows={2}
            arrows={false}
            autoplay={false}
            pauseOnHover
            slidesToShow={5}
            slidesToScroll={5}
            style={{ paddingBottom: "50px", minHeight: "310px" }}
            effect=""
            responsive={[
              {
                breakpoint: 1100,
                settings: {
                  rows: 3,
                  slidesToShow: 4,
                  slidesToScroll: 4,
                },
              },
              {
                breakpoint: 800,
                settings: {
                  rows: 3,
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 400,
                settings: {
                  rows: 4,
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 300,
                settings: {
                  rows: 4,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {partners.map(({ name, img }) => (
              <Partner name={name} img={img} key={name} />
            ))}
          </Carousel>
        </Container>
      </div>
    );
  }
  return (
    <div className="background"  id="partners">
      <Container
        style={{
          padding: "60px 0",
          borderTop: "var(--border)",
          borderBottom: "var(--border)",
        }}
        useMargins={false}
        usePadding={false}
      >
        <div>
          <Partners partners={partners} />
        </div>
      </Container>
    </div>
  );
};

Partner.propTypes = {
  name: PropTypes.string,
  img: PropTypes.any,
};

Partners.propTypes = {
  partners: PropTypes.array,
};
