import { Col, Layout, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { PageRoutes } from "../api/PageRoutes";
import logo from "../images/crai_logo_mb.svg";
import Container from "./Container";
import { PrimaryBtn } from "./PrimaryBtn";

const { Footer } = Layout;

const PageFooter = () => {
  return (
    <Footer className="background-dark">
      <Container>
        <Row gutter={[32, 32]}>
          <Col span={24} className="text-faded">
            <Link to={`/home`}>
              <img
                src={logo}
                height="30"
                style={{
                  marginBottom: "40px",
                  filter: `grayscale(100%) brightness(900%)`,
                }}
              />
            </Link>
          </Col>
          {PageRoutes ?.map(({ page, link, sections }) => (
            <Col key={page} xs={12} sm={8} md={6} lg={4}>
              <ul
                style={{ listStyleType: "none", padding: 0 }}
                className="text-faded pointer"
              >
                <li>
                  <Link className="text-white text-small" to={`${link}`}>
                    {page}
                  </Link>
                </li>
                {sections.map(({ id }) => (
                  <li key={id} style={{ marginTop: "12px" }}>
                    <Link
                      className="text-faded text-small"
                      to={`${link}#${id}`}
                    >
                      {id}
                    </Link>
                  </li>
                ))}
              </ul>
            </Col>
          ))}:
          <Col xs={24} sm={8} md={6} lg={4}>
            <a title="contact@crai.no" href="mailto:contact@crai.no">
              <PrimaryBtn arrowRight bounce={false}>
                Contact us
              </PrimaryBtn>
            </a>
          </Col>
          <Col span={24} className="text-faded">
            <div style={{ marginTop: "40px" }}>
              Copyright © {new Date().getFullYear()} CRAI. All rights reserved.
            </div>
          </Col>
        </Row>
      </Container>
    </Footer>
  );
};

PageFooter.propTypes = {
  setCurrentPath: PropTypes.func,
};

export default PageFooter;
