import { Col, Row } from "antd";
import React from "react";
import Particles from "react-particles-js";
import { AnimatedPresence } from "../../../components/AnimatedPresence";
import Container from "../../../components/Container";
import { breakpoints, windowWidth } from "../../../hooks/useWindowWidth";
import {
  LandingCardsLarge,
  LandingCardsMedium,
  LandingCardsSmall,
} from "./LandingCards";
import LandingTextSection from "./LandingTextSection";

const LandingSection = () => {
  const [width] = windowWidth();

  const textSection = (
    <>
      {width > breakpoints.large ? (
        <Row>
          <LandingTextSection
            width={width}
            breakpoints={breakpoints}
            animate={false}
          />
        </Row>
      ) : (
        <Row>
          <div style={{ margin: "80px 0" }}>
            <LandingTextSection
              width={width}
              breakpoints={breakpoints}
              animate={false}
            />
          </div>
        </Row>
      )}
    </>
  );

  const content = (
    <>
      <Row
        gutter={[32, 0]}
        align="middle"
        style={{
          minHeight: "100vh",
        }}
      >
        <Col xs={width < breakpoints.large ? 24 : 13}>
          {width < breakpoints.large ? (
            <Container style={{ padding: "0" }}>{textSection}</Container>
          ) : (
            <>{textSection}</>
          )}
        </Col>
        <Col xs={width < breakpoints.large ? 0 : 11}>
          <LandingCardsLarge />
        </Col>
        <Col
          xs={
            width < breakpoints.large
              ? width > breakpoints.medium
                ? 24
                : 0
              : 0
          }
        >
          <LandingCardsMedium />
        </Col>
        <Col
          xs={
            width > breakpoints.large ? 0 : width > breakpoints.medium ? 0 : 24
          }
        >
          <LandingCardsSmall />
        </Col>
      </Row>
    </>
  );

  // No container on smaller devices
  if (width < breakpoints.large) {
    return (
      <div className="gradient-reverse" style={{ paddingBottom: "60px" }}>
        {content}
      </div>
    );
  }

  // Container on desktop
  return (
    <div className="radial-gradient-landing " style={{ paddingBottom: "40px" }}>
      <Container style={{ padding: "0", position: "relative", zIndex: 1 }}>
        {content}
      </Container>
      <AnimatedPresence
        transition={{ duration: 2, delay: 0.5 }}
        variants={{
          visible: { opacity: 0.3 },
          hidden: { opacity: 0 },
        }}
      >
        <Particles
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: 0,
          }}
        />
      </AnimatedPresence>
    </div>
  );
};

export default LandingSection;
