import { Drawer } from "antd";
import Hamburger from "hamburger-react";
import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { PageRoutes } from "../../api/PageRoutes";
import { AnimatedPresence } from "../AnimatedPresence";

const MobileDrawer = () => {
  const [visible, setVisible] = useState(false);

  const transition = {
    duration: 0.3,
    type: "spring",
    damping: 9,
    mass: 0.6,
    stiffness: 40,
  };

  const variants = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 25 },
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "10px",
          right: "20px",
          zIndex: 9999,
        }}
      >
        <Hamburger
          toggled={visible}
          onToggle={() => setVisible(!visible)}
          color="var(--faded-white)"
          size={24}
        />
      </div>
      <Drawer
        destroyOnClose
        title={<div></div>}
        placement="right"
        size="large"
        visible={visible}
        closable={false}
      >
        {PageRoutes.map((item) => (
          <Link
            key={item.link}
            to={`${item.link}`}
            onClick={() => setVisible(!visible)}
          >
            <div
              className="pointer ant-drawer-item"
              style={{
                padding: "20px",
                borderBottom: "1px solid var(--dark-light)",
              }}
            >
              {visible && (
                <AnimatedPresence
                  transition={{
                    ...transition,
                    delay: PageRoutes.indexOf(item) * 0.1,
                  }}
                  variants={variants}
                >
                  <p className="text-small" style={{ margin: 0 }}>
                    {item.page}
                  </p>
                </AnimatedPresence>
              )}
            </div>
          </Link>
        ))}
      </Drawer>
    </>
  );
};

export default MobileDrawer;
