import { Col, Row } from "antd";
import React from "react";
import LazyLoad from "react-lazyload";
import Container from "../../components/Container";
import TextSection from "../../components/TextSection";

export const MissionSection = () => (
  <div
    className="background-dark"
    style={{
      padding: "10vh 0",
      position: "relative",
    }}
  >
    <Container>
      <Row justify="center" align="middle" gutter={[64, 64]}>
        <Col order={2} xs={24} lg={12}>
          <LazyLoad once offset={50}>
            <img
              className="rounded"
              src={
                "https://images.unsplash.com/photo-1617791160536-598cf32026fb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064&q=80"
              }
              style={{
                height: "500px",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </LazyLoad>
        </Col>
        <Col order={1} xs={24} lg={12}>
          <TextSection
            brow="MISSION"
            header="OUR MISSION"
            body={
              <>
                CRAI’s mission is to develop and evaluate AI-based technology
                for improved quality and efficiency of diagnostic radiology
                services at Oslo University Hospital and collaborating partners.
                This will be achieved through in-house research and clinical
                deployment of own solutions as well as through testing and
                implementation of commercial AI solutions.
                <br />
                <br />
                <span className="quote text-medium text-faded">
                  -- To make the radiology workflow more efficient through
                  AI-powered solutions
                </span>
              </>
            }
          />
        </Col>
      </Row>
    </Container>
  </div>
);
