import PropTypes from "prop-types";
import React from "react";
import { AnimatedPresence } from "./AnimatedPresence";

const TextSection = ({
  brow,
  header,
  body,
  delay = [0, 0, 0],
  color = "text-blue",
  animate = true,
}) => {
  return (
    <div>
      <AnimatedPresence transition={{ delay: delay[0] }} animate={animate}>
        <h3 className={color} style={{ marginBottom: 0 }}>
          {brow}
        </h3>
      </AnimatedPresence>
      <AnimatedPresence transition={{ delay: delay[1] }} animate={animate}>
        {typeof header === "string" ? (
          <h1 className="text-xlarge text-white" style={{ fontWeight: "700" }}>
            {header}
          </h1>
        ) : (
          <div className="text-white">{header}</div>
        )}
      </AnimatedPresence>
      <AnimatedPresence transition={{ delay: delay[2] }} animate={animate}>
        <div className="text-medium text-faded">{body}</div>
      </AnimatedPresence>
    </div>
  );
};

TextSection.propTypes = {
  brow: PropTypes.any,
  header: PropTypes.any.isRequired,
  body: PropTypes.node.isRequired,
  delay: PropTypes.array,
  color: PropTypes.string,
  animate: PropTypes.bool,
};

export default TextSection;
