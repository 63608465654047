import amsterdamUmcLogo from "../images/partners/Amsterdam-UMC.png";
import auLogo from "../images/partners/AU.png";
import cerebriuLogo from "../images/partners/Cerebriu-Logo.svg";
import eurostarsLogo from "../images/partners/eurostars.png";
import lcbcLogo from "../images/partners/LCBC_wide_compact_full.png";
import strokeSensLogo from "../images/partners/logo-StrokeSENS.png";
import nmbuLogo from "../images/partners/nmbu_logo.png";
import nilLogo from "../images/partners/NNL_logo.png";
import ousLogo from "../images/partners/OUS_logo_HVIT_eng.png";
import sorostLogo from "../images/partners/sorost.svg";
import uioLogo from "../images/partners/uio.png";

export const partners = [
  {
    name: "Norwegian University of Life Sciences",
    img: nmbuLogo,
  },
  {
    name: "Amsterdam UMC",
    img: amsterdamUmcLogo,
  },
  {
    name: "LCBC",
    img: lcbcLogo,
  },
  {
    name: "HELSE SØR-ØST",
    img: sorostLogo,
  },
  {
    name: "Eurostars",
    img: eurostarsLogo,
  },
  {
    name: "NordicImagingLab",
    img: nilLogo,
  },
  {
    name: "cerebriu",
    img: cerebriuLogo,
  },
  {
    name: "StrokeSENS",
    img: strokeSensLogo,
  },
  {
    name: "Akershus universitetssykehus",
    img: auLogo,
  },
  {
    name: "UIO: Department of Informatics",
    img: uioLogo,
  },
  {
    name: "Oslo universitetssykehus",
    img: ousLogo,
  },
];
