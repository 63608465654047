import { Space } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { MdOpenInNew } from "react-icons/md";
import { useScrollSection } from "react-scroll-section";
import { AnimatedPresence } from "../../../components/AnimatedPresence";
import { PrimaryBtn } from "../../../components/PrimaryBtn";
import { ViewMore } from "../../../components/ViewMore";
import logo from "../../../images/crai_logo_mb.svg";
import ous from "../../../images/partners/OUS_logo_HVIT_eng.png";

const transition = {
  duration: 0.3,
  type: "spring",
  damping: 9,
  mass: 0.6,
  stiffness: 40,
};

const LandingTextSection = ({ animate = true, width, breakpoints }) => {
  const about = useScrollSection("About");

  return (
    <div
      className="landing-text-wrapper "
      style={{
        maxWidth: "583px",
        padding: "10px",
        borderRadius: "6px",
        //boxShadow: "var(--dark-dark) 0px 5px 30px 30px",
        backdropFilter: "blur(30px)",
      }}
    >
      <AnimatedPresence
        animate={animate}
        transition={{
          ...transition,
          delay: 0,
        }}
      >
        {width > breakpoints.medium && (
          <Space
            size={30}
            align="center"
            style={{ marginBottom: "40px", width: "100%" }}
          >
            <img src={logo} height="40" />
            <div
              className="rounded"
              style={{
                height: "30px",
                width: "2px",
                backgroundColor: "var(--faded-white)",
              }}
            ></div>
            <a
              href="https://oslo-universitetssykehus.no/oslo-university-hospital"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ous} height="30" />
            </a>
          </Space>
        )}
      </AnimatedPresence>
      <h1 className="text-white text-landing">
        <AnimatedPresence
          animate={animate}
          transition={{
            ...transition,
            delay: 0.1,
          }}
        >
          Computational Radiology and <span className="text-blue">AI</span>
        </AnimatedPresence>
      </h1>
      <AnimatedPresence
        animate={animate}
        transition={{ delay: 0.6, duration: 1 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
      >
        <p className="text-medium text-faded">
          We&apos;re the <span>CRAI</span> unit at the Division of Radiology and
          Nuclear Medicine -{" "}
          <a
            href="https://oslo-universitetssykehus.no/oslo-university-hospital"
            target="_blank"
            rel="noopener noreferrer"
          >
            Oslo University Hospital (OUS)
          </a>
          . Our team consists of a motivated and dedicated group of scientists,
          software engineers, medical doctors, and PhD/Masters students with a
          common interest in diagnostic imaging and machine learning.
        </p>
      </AnimatedPresence>
      <br />
      <AnimatedPresence
        animate={animate}
        transition={{ delay: 0.6, duration: 1 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
      >
        {width > breakpoints.small ? (
          <Space size={"large"}>
            <PrimaryBtn onClick={about.onClick}>About us</PrimaryBtn>
            <a
              className="view-more"
              href="https://oslo-universitetssykehus.no/oslo-university-hospital"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ViewMore
                text="OUS website"
                icon={<MdOpenInNew size={20} style={{ marginTop: "10px" }} />}
              />
            </a>
          </Space>
        ) : (
          <>
            <PrimaryBtn block bounce={false} onClick={about.onClick}>
              About us
            </PrimaryBtn>
            <br />
            <br />
            <a
              className="view-more"
              href="https://oslo-universitetssykehus.no/oslo-university-hospital"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ViewMore
                text="OUS website"
                icon={<MdOpenInNew size={20} style={{ marginTop: "10px" }} />}
              />
            </a>
          </>
        )}
      </AnimatedPresence>
    </div>
  );
};

export default LandingTextSection;

LandingTextSection.propTypes = {
  animate: PropTypes.bool,
  width: PropTypes.any,
  breakpoints: PropTypes.object,
};
