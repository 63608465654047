import { UserOutlined } from "@ant-design/icons";
import { PortableText } from "@portabletext/react";
import { Avatar, Popover, Space } from "antd";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React, { useState } from "react";
import sanityClient from "../../../api/Client";
import { urlFor } from "../../research/articles/ArticlePage";

const memberInfoPopover = (member) => (
  <div className="background-light rounded" style={{ padding: "30px" }}>
    <Space>
      <div>
        <Avatar
          size={100}
          src={
            <img
              src={urlFor(member.image).width(190).url() || null}
              alt={member.name}
              title={member.name}
            />
          }
        />
      </div>
      <div
        style={{ paddingLeft: "20px" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
      >
        <div className="text-small text-white">{member.projects}</div>
        <div className="text-large text-white">{member.name}</div>
        <div className="text-small text-faded" style={{ marginBottom: "-1em" }}>
          <PortableText
            value={member.bio}
            projectId={sanityClient.config().projectId}
            dataset={sanityClient.config().dataset}
          />
        </div>
      </div>
    </Space>
  </div>
);

export const Member = ({ member, mobileView = false }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Popover
      content={memberInfoPopover(member)}
      trigger="click"
      className="pointer"
    >
      <div style={{ height: "181px" }}>
        <motion.div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          title={member.name}
          className="pointer"
          style={{
            padding: mobileView ? "0px" : "20px",
          }}
          layoutId={member}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Avatar
              size={100}
              style={{
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
                opacity: isHovered ? 0.15 : 0,
                transition: ".5s ease",
                backgroundColor: "white",
                zIndex: 2,
              }}
            />
            <Avatar
              size={100}
              src={urlFor(member.image).width(190).url() || null}
              icon={<UserOutlined />}
              style={{
                color: "var(--dark-light)",
              }}
              className="wave-bg"
            />
          </div>
          <p
            className={`text-small ${isHovered ? "text-white" : "text-faded"}`}
            style={{ textAlign: "center" }}
          >
            {member.name.split(" ")[0]}
          </p>
        </motion.div>
      </div>
    </Popover>
  );
};
Member.propTypes = {
  member: PropTypes.any.isRequired,
  mobileView: PropTypes.bool,
};
