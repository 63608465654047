import { Avatar, Col, Popover, Row, Space } from "antd";
import { motion } from "framer-motion";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import { Link } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import Container from "../../../components/Container";
import ScrollToTop from "../../../components/ScrollToTop";
import TextSection from "../../../components/TextSection";
import { dateFormatter } from "../../../dateFormatter";
import { breakpoints, windowWidth } from "../../../hooks/useWindowWidth";
import { urlFor } from "./ArticlePage";

export const categoriesToSting = (categories) => {
  if (!categories) return <span>NEWS</span>;
  return categories.map((c) => (
    <span key={c}>
      {c.toUpperCase()}
      {categories.indexOf(c) !== categories.length - 1 && ", "}
    </span>
  ));
};

const authorInfoPopover = (author) => (
  <div className="background-light rounded" style={{ padding: "30px" }}>
    <Space>
      <div>
        <Avatar
          size={100}
          src={
            <img
              src={urlFor(author.image).width(190).url()}
              alt={author.name}
              title={author.name}
            />
          }
        />
      </div>
      <div
        style={{ paddingLeft: "20px" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
      >
        <div className="text-small text-white">{author.projects}</div>
        <div className="text-large text-white">{author.name}</div>
        <div className="text-small text-faded" style={{ marginBottom: "-1em" }}>
          {author.bio}
        </div>
      </div>
    </Space>
  </div>
);

const AuthorInfo = ({ author, publishedAt, updatedAt }) => {
  let isEdited = !moment(publishedAt).isSame(updatedAt, "day");
  return (
    <Popover
      content={authorInfoPopover(author)}
      trigger="click"
      className="pointer"
    >
      <Space>
        <Avatar
          size={42}
          src={
            <img
              src={urlFor(author.image).width(190).url()}
              alt={author.name}
              title={author.name}
            />
          }
        />
        <div style={{ paddingLeft: "10px" }}>
          <div className="text-white text-small">{author.name}</div>
          <div className="text-small text-faded">
            <Row>
              <Col xs={0} sm={24}>
                <Space
                  align="center"
                  className="text-small"
                  split={<span className="text-faded"> · </span>}
                >
                  <div>{dateFormatter(publishedAt)}</div>
                  {isEdited && (
                    <div>Last edited {dateFormatter(updatedAt)}</div>
                  )}
                </Space>
              </Col>
              <Col xs={24} sm={0}>
                <div>{dateFormatter(publishedAt)}</div>
              </Col>
            </Row>
          </div>
        </div>
      </Space>
    </Popover>
  );
};

const ShareButtons = ({ url }) => (
  <div>
    <Space>
      <TwitterShareButton url={url} title="Share this article on Twitter">
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <FacebookShareButton url={url} title="Share this article on Facebook">
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <LinkedinShareButton url={url} title="Share this article on LinkedIn">
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
    </Space>
  </div>
);

const ArticleInfo = ({ author, publishedAt, updatedAt, url }) => {
  return (
    <div className="text-faded" style={{ margin: "0.5rem 0px 0px 0px" }}>
      <div>
        <Row justify="space-between" align="middle" gutter={[0, 16]}>
          <Col>
            <AuthorInfo
              author={author}
              publishedAt={publishedAt}
              updatedAt={updatedAt}
            />
          </Col>
          <Col></Col>
          <Col>
            <div style={{ marginTop: "7px" }}>
              <ShareButtons url={url} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default function Article({
  slug,
  categories,
  title,
  mainImage,
  body,
  publishedAt,
  updatedAt,
  author,
}) {
  const [width] = windowWidth();

  const currentUrl = window.location.pathname;
  const url = currentUrl.includes("/research/article/")
    ? `https://crai.no/research/article/${slug}`
    : `https://crai.no/news/article/${slug}`;
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    setCategoryList(categoriesToSting(categories));
  }, []);

  return (
    <Container>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.4,
        }}
      >
        <ScrollToTop />
        <Row justify="center" gutter={[32, 32]}>
          <Col sm={24} lg={18}>
            {width > breakpoints.medium && (
              <Breadcrumbs
                page={title}
                prevUrl={
                  currentUrl.includes("/research/article/")
                    ? "research"
                    : "news"
                }
              />
            )}
            <TextSection
              brow={categoryList}
              header={title}
              body={
                <>
                  <ArticleInfo
                    author={author}
                    publishedAt={publishedAt}
                    updatedAt={updatedAt}
                    url={url}
                  />
                  {mainImage && (
                    <>
                      <img
                        className="rounded-lg"
                        style={{
                          marginTop: "2.25rem",
                          width: "100%",
                          height: "auto",
                        }}
                        src={urlFor(mainImage).width(1000).url()}
                        alt={mainImage.asset.alt || ""}
                        title={mainImage.asset.title || ""}
                      />
                      <div
                        style={{ marginTop: "8px", opacity: 0.7 }}
                        className="text-small"
                      >
                        <span>{mainImage.asset.description}</span>
                      </div>
                    </>
                  )}
                  <div style={{ paddingTop: "40px" }}></div>
                  <div
                    style={{
                      color: "rgba(255,255,255,0.9)",
                      lineHeight: "1.75",
                    }}
                  >
                    {body}
                  </div>
                </>
              }
              animate={false}
            />
          </Col>
        </Row>
      </motion.div>
    </Container>
  );
}

function truncate(str, n) {
  return str.length > n ? <>{str.substr(0, n - 1)}&hellip;</> : str;
}

const Breadcrumbs = ({ page, prevUrl }) => (
  <h3
    style={{
      paddingTop: "50px",
      paddingBottom: "1.875rem",
    }}
  >
    <Space align="center">
      <Link to={`/${prevUrl}`}>
        <span className="text-white">{prevUrl.toUpperCase()}</span>
      </Link>
      <ChevronRight
        className="text-faded"
        style={{ lineHeight: 0, marginTop: "6px" }}
      />
      <span className="text-faded" style={{ marginBottom: 0 }}>
        {truncate(page.toUpperCase(), 65)}
      </span>
    </Space>
  </h3>
);

ShareButtons.propTypes = {
  url: PropTypes.string.isRequired,
};

AuthorInfo.propTypes = {
  author: PropTypes.object.isRequired,
  publishedAt: PropTypes.any.isRequired,
  updatedAt: PropTypes.any,
};

ArticleInfo.propTypes = {
  author: PropTypes.object.isRequired,
  publishedAt: PropTypes.string,
  updatedAt: PropTypes.string,
  url: PropTypes.string.isRequired,
};

Article.propTypes = {
  slug: PropTypes.string,
  categories: PropTypes.array,
  title: PropTypes.string,
  mainImage: PropTypes.any,
  body: PropTypes.any,
  publishedAt: PropTypes.string,
  updatedAt: PropTypes.string,
  author: PropTypes.object,
};

Breadcrumbs.propTypes = {
  page: PropTypes.any,
  prevUrl: PropTypes.string,
};
