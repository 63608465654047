import PropTypes from "prop-types";
import React from "react";
import { windowWidth } from "../hooks/useWindowWidth";
function SwipeTrack({
  children,
  height = "355px",
  grid = `1fr/repeat(5, ${windowWidth > 1200 ? "254px" : "200px"})`,
  gap = "16px",
  style,
}) {
  return (
    <div
      style={{
        height: height,
        width: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div
        style={{
          ...style,
          height: `calc(${height} + 20px)`,
          width: "100%",
          overflowX: "auto",
          overflowY: "hidden",
          overscrollBehaviorX: "contain",
          paddingLeft: "24px",
          paddingRight: "24px",
          top: 0,
          position: "absolute",
          display: "grid",
          grid: grid,
          gap: gap,
        }}
      >
        {children}
      </div>
    </div>
  );
}

SwipeTrack.propTypes = {
  children: PropTypes.any,
  height: PropTypes.string,
  grid: PropTypes.string,
  gap: PropTypes.string,
  style: PropTypes.object,
};

export default SwipeTrack;
