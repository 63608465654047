import { Space } from "antd";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";
import { ChevronRight } from "react-feather";
import { HashLink as Link } from "react-router-hash-link";
import { navItems } from "../../../components/nav/SideNav";

const ImageColorOverlay = ({ link, overlay, gradient }) => {
  return (
    <motion.div
      variants={link && overlay}
      style={{
        background: link ? gradient : "transparent",
        opacity: 0.4,
        position: "absolute",
        top: "-5px",
        height: "110%",
        width: "100%",
        zIndex: 2,
      }}
    />
  );
};

const backgroundImage = {
  hover: {
    scale: 1,
    opacity: 0.8,
    transition: {
      type: "tween",
      duration: 0.2,
    },
  },
  initial: {
    scale: 1.1,
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.2,
    },
  },
};

const chevron = {
  hover: {
    x: 10,
    transition: {
      type: "tween",
      duration: 0.2,
    },
  },
  initial: {
    x: 0,
    transition: {
      type: "tween",
      duration: 0.2,
    },
  },
};

const viewMoreText = {
  hover: {
    opacity: 1,
    transition: {
      type: "tween",
      duration: 0.2,
    },
  },
  initial: {
    opacity: 0,
    transition: {
      type: "tween",
      duration: 0.2,
    },
  },
};

const cardContent = {
  hover: {
    y: 0,
    transition: {
      duration: 0.2,
      type: "spring",
      damping: 6,
      mass: 0.3,
      stiffness: 60,
    },
  },
  initial: {
    y: 30,
    transition: {
      duration: 0.2,
      type: "spring",
      damping: 8,
      mass: 0.3,
      stiffness: 60,
    },
  },
};

const LandingCard = ({
  header,
  body,
  actionBtnTxt = "Learn more",
  link,
  linkid,
  target_value,
  showIcon = true,
  img,
  gradient,
  className = "mix-blend-screen background-light rounded pointer landing-card-dimentions ",
}) => {  
  const LandingCardBody = () => {
    return (
      <motion.div
        title={link}
        className={`${className} landing-card `}
        style={{
          opacity: link ? "inherit" : 0.6,
        }}
        initial="initial"
        whileHover="hover"
        animate="initial"
      >
        <div
          style={{
            padding: "16px",
            zIndex: 3,
            position: "relative",
            height: "100%",
          }}
        >
          <div style={{ textShadow: "2px 2px 6px rgba(0,0,0,0.2)" }}>
            <div
              className="text-white text-medium"
              style={{ paddingRight: "25px" }}
            >
              {header}
            </div>
            {showIcon && (
              <div
                style={{
                  position: "absolute",
                  right: "16px",
                  top: "20px",
                }}
                className="text-white"
              >
                {link &&
                  navItems
                    .filter((item) => link.includes(item.title))
                    .slice(0, 1)
                    .map(({ title, icon }) => <div key={title}>{icon}</div>)}
              </div>
            )}
          </div>
          <div>{body}</div>
          <motion.div
            variants={cardContent}
            style={{
              position: "absolute",
              bottom: "4px",
            }}
          >
            {link && (
              <motion.div variants={viewMoreText}>
                <Space>
                  <h1
                    className="text-white text-small"
                    style={{ fontWeight: "600" }}
                  >
                    {actionBtnTxt}
                  </h1>
                  <motion.div variants={chevron}>
                    <ChevronRight className="text-white" />
                  </motion.div>
                </Space>
              </motion.div>
            )}
          </motion.div>
        </div>

        <div
          style={{
            height: "100%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <motion.img
            src={img}
            style={{
              height: "100%",
              objectFit: "cover",
            }}
            variants={link && backgroundImage}
          />
          <ImageColorOverlay gradient={gradient} link={link} />
        </div>
      </motion.div>
    );
  };

  if (!link) return <LandingCardBody />;
  if(linkid) return ( <Link 
    to={`${linkid}`}
    target={target_value}>
      <LandingCardBody />
    </Link>);
  return (
    <Link 
    to={{pathname:`${link}` }}
    target={target_value}>
      <LandingCardBody />
    </Link>
  );
};

LandingCard.propTypes = {
  header: PropTypes.any,
  body: PropTypes.any,
  actionBtnTxt: PropTypes.string,
  showIcon: PropTypes.bool,
  link: PropTypes.string,
  linkid: PropTypes.string,
  target_value:PropTypes.string,
  img: PropTypes.any,
  gradient: PropTypes.string,
  className: PropTypes.string,
};

ImageColorOverlay.propTypes = {
  gradient: PropTypes.string,
  link: PropTypes.any,
  overlay: PropTypes.any,
};

export default LandingCard;
