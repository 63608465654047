import { Button, Space } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ChevronDown, ChevronRight } from "react-feather";

export const PrimaryBtn = ({
  children,
  onClick,
  bounce = true,
  arrowRight = false,
  block = false,
  arrow = true,
  buttonStyle,
  disabled = false,
  title = "",
  outlined = false,
}) => {
  const [hover, setHover] = useState(false);

  if (outlined)
    return (
      <Button
        type="primary"
        className="text-medium"
        title={title}
        style={{
          width: block ? "100%" : "auto",
          backgroundColor: "transparent",
          border: hover ? "2px solid white" : "2px solid var(--blue)",
          color: hover ? "white" : "var(--blue)",
          cursor: "pointer",
          padding: "12px 35px",
          height: "auto",
          borderRadius: "10px",
          fontWeight: 500,
          ...buttonStyle,
        }}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Space align="center">
          {children}
          <div style={{ lineHeight: 0 }}>
            {arrow ? (
              arrowRight ? (
                <ChevronRight className={bounce ? `bounce` : ``} />
              ) : (
                <ChevronDown className={bounce ? `bounce` : ``} />
              )
            ) : null}
          </div>
        </Space>
      </Button>
    );

  return (
    <Button
      type="primary"
      className="text-medium"
      title={title}
      style={{
        width: block ? "100%" : "auto",
        backgroundColor: disabled
          ? "#617487"
          : hover
          ? "#65c4f7"
          : "var(--blue)",
        border: disabled
          ? "2px solid #617487"
          : hover
          ? "2px solid #65c4f7"
          : "2px solid var(--blue)",
        color: disabled ? "var(--faded-white)" : "var(--white)",
        cursor: disabled ? "not-allowed" : "pointer",
        padding: "12px 35px",
        height: "auto",
        borderRadius: "10px",
        fontWeight: 500,
        boxShadow: "0px 5px 24px rgba(48, 167, 239, 0.3)",
        ...buttonStyle,
      }}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Space align="center">
        {children}
        <div style={{ lineHeight: 0 }}>
          {arrow ? (
            arrowRight ? (
              <ChevronRight className={bounce ? `bounce` : ``} />
            ) : (
              <ChevronDown className={bounce ? `bounce` : ``} />
            )
          ) : null}
        </div>
      </Space>
    </Button>
  );
};

PrimaryBtn.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  bounce: PropTypes.bool,
  arrowRight: PropTypes.bool,
  block: PropTypes.bool,
  arrow: PropTypes.bool,
  buttonStyle: PropTypes.object,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  outlined: PropTypes.bool,
};
