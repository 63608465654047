import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";

const Container = ({
  children,
  style = { padding: "60px 0" },
  useMargins = true,
  usePadding = true,
}) => (
  <Row justify="center" align="middle" gutter={[42, 16]} style={style}>
    <Col
      style={{
        width: "100%",
        maxWidth: "1200px",
        margin: useMargins ? "0 24px" : "0",
        padding: usePadding ? "auto" : "0",
      }}
    >
      {children}
    </Col>
  </Row>
);

Container.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  useMargins: PropTypes.bool,
  usePadding: PropTypes.bool,
};

export default Container;
