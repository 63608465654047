import { Col, Layout, Menu, Row, Space } from "antd";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Box, Briefcase, FileText, Home as HomeIcon } from "react-feather";
import { BiNews } from "react-icons/bi";
import { MdArticle } from "react-icons/md";

import { Link, useLocation } from "react-router-dom";
//import clsx from "clsx";
//import { useCookies } from "react-cookie";
import "../../css/global-styles.css";
import {
  default as logo,
  default as logoMax,
} from "../../images/crai_logo_mb.svg";
import ous from "../../images/partners/OUS_logo_HVIT_eng.png";
import Container from "../Container";

//import crai from "./../images/CRAI_LOGO_ONLY.svg";
const { Sider } = Layout;

export const navItems = [
  { title: "home", icon: <HomeIcon size={18} className="anticon" /> },
  { title: "research", icon: <FileText size={18} className="anticon" /> },
   {
     title: "publications",
     icon: <MdArticle  size={18} className="anticon" />,
   },
  { title: "products", icon: <Box size={18} className="anticon" /> },
  { title: "news", icon: <BiNews size={18} className="anticon" /> },
  { title: "careers", icon: <Briefcase size={18} className="anticon" /> },
];

const NavDrawer = () => {
  const [collapsed, setCollapsed] = useState(true);
  const currentPath = useLocation();
  const [location, setLocation] = useState(currentPath);
  const [activeTab, setActiveTab] = useState("/home");

  useEffect(() => {
    setLocation(currentPath);
    let tab = navItems.filter((item) =>
      location.pathname.split("/")[1].includes(item.title)
    )[0];
    setActiveTab(tab ? `/${tab.title}` : null);
  }, [currentPath, location]);
  //const [cookies, , removeCookie] = useCookies(["token"]);

  //const logout = () => {
  //removeCookie('token');
  //}

  return (
    <>
      <div style={{ width: "80px" }} className="side-nav" />
      <Sider
        className="side-nav"
        onMouseEnter={() => setCollapsed(false)}
        onMouseLeave={() => setCollapsed(true)}
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        onClick={() => setCollapsed(true)}
        style={{
          height: "100vh",
          position: "fixed",
          left: 0,
          zIndex: 999,
        }}
      >
        <div style={{ height: "72px", position: "relative" }}>
          <Link to="/">
            <div
              style={{
                height: "30px",
                maxWidth: collapsed ? "26px" : "100%",
                overflow: "hidden",
                position: "absolute",
                top: "calc(50% - 15px)",
                left: collapsed ? "31px" : "21px",
                transition: "all 0.2s ",
              }}
            >
              <img
                className="logo"
                src={logoMax}
                style={{
                  height: "30px",
                  width: "auto",
                }}
              />
            </div>
          </Link>
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={[location.pathname]}
          selectedKeys={location.pathname === "/" ? ["/home"] : [activeTab]}
          mode="inline"
          className="unselectable"
        >
          {navItems.map((item) => (
            <Menu.Item key={`/${item.title}`} icon={item.icon}>
              {item.title === "contact" ? (
                <a href="mailto:contact@crai.no">
                  {item.title.charAt(0).toUpperCase() +
                    item.title.toLowerCase().slice(1)}
                </a>
              ) : (
                <Link to={"/" + item.title.toLowerCase()}>
                  {item.title.charAt(0).toUpperCase() +
                    item.title.toLowerCase().slice(1)}
                </Link>
              )}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
    </>
  );
};

NavDrawer.propTypes = {
  location: PropTypes.object,
};

export default NavDrawer;

export const TopNav = () => {
  return (
    <div
      className="background-dark"
      style={{ position: "relative", zIndex: 3 }}
    >
      <Container style={{ padding: "0" }}>
        <Row
          align="middle"
          style={{
            height: "100px",
          }}
        >
          <Col xs={24}>
            <div>
              <Row justify="space-between">
                <Col style={{ padding: "4px 0" }}>
                  <Space size={30} align="center" style={{ width: "100%" }}>
                    <img src={logo} height="32" />
                    <div
                      className="rounded"
                      style={{
                        height: "20px",
                        width: "2px",
                        backgroundColor: "var(--faded-white)",
                      }}
                    ></div>
                    <a
                      href="https://oslo-universitetssykehus.no/oslo-university-hospital"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <motion.img
                        src={ous}
                        height="28"
                        initial={{ opacity: 0.9, scale: 1 }}
                        whileHover={{ opacity: 1, scale: 0.95 }}
                      />
                    </a>
                  </Space>
                </Col>
                <Col
                  className="text-small text-faded"
                  style={{ fontWeight: "500" }}
                >
                  <Space size={50}>
                    <div>About</div>
                    <div>Mission</div>
                    <div>The Team</div>

                    <div
                      className="rounded text-faded"
                      style={{
                        padding: "4px 18px",
                        border: "2px solid var(--faded-white)",
                      }}
                    >
                      Contact
                    </div>
                  </Space>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
