import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { ParallaxCache } from "./Parallax";

function ScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <ParallaxCache />;
}

export default withRouter(ScrollToTop);
