import sanityClient from "./Client";

export const fetchAllResearchArticles = async () => {
  let query = `*[_type == "research"][0..30] | order(_createdAt asc) {
        title,
        slug,
        "categories": categories[]->title,
        mainImage{
          asset->{
            _id,
            url
          }
        },
        publishedAt,
        "name": author->name,
      }`;

  const result = await sanityClient.fetch(query);

  return result;
};

export const fetchOneResearchArticle = async (id) => {
  let query = `*[slug.current == $id]{
    "categories": categories[]->title,
        title,
        slug,
        mainImage{
          asset->{
            _id,
            url,
            title,
            altText,
            description
           }
         },
       body[]{
        ...,
        _type == "image" => {
          ...,
          asset->{
            _id,
            url,
            title,
            altText,
            description
          }
        }
       },
       publishedAt,
       "updatedAt": _updatedAt,
      "name": author->name,
      "authorImage": author->image,
      "authorBio": author->bio,
      "authorProjects": author->projects,
      "authorMail": author->mail,
     }`;

  const result = await sanityClient.fetch(query, { id });

  return result[0];
};

export const fetchAllNews = async () => {
  let query = `*[_type == "news"][0..14]| order(_createdAt desc) {
        title,
        slug,
        publishedAt,
        body,
        mainImage{
          asset->{
            _id,
            url
          }
        },
        "name": author->name,
        "authorImage": author->image,
        "authorBio": author->bio,
        "authorProjects": author->projects,
        "authorMail": author->mail,
      }`;

  const result = await sanityClient.fetch(query);

  return result;
};

export const fetchOneNewsArticle = async (id) => {
  let query = `*[slug.current == $id]{
    "categories": categories[]->title,
        title,
        slug,
        mainImage{
          asset->{
            _id,
            url,
            title,
            altText,
            description
           }
         },
       body[]{
        ...,
        _type == "image" => {
          ...,
          asset->{
            _id,
            url,
            title,
            altText,
            description
          }
        }
       },
       publishedAt,
       "updatedAt": _updatedAt,
      "name": author->name,
      "authorImage": author->image,
      "authorBio": author->bio,
      "authorProjects": author->projects,
      "authorMail": author->mail,
     }`;

  const result = await sanityClient.fetch(query, { id });

  return result[0];
};

export const fetchAllEmployees = async () => {
  let query = `*[_type == "employee"]{
    name,
    slug,
    projects,
    mail,
    bio,
    image{
      asset->{
        _id,
        url
      }
    }
  }`;

  const result = await sanityClient.fetch(query);

  return result;
};

export const fetchAllServices = async () => {
  let query = `*[_type == "service"]| order(_createdAt asc) {
        title,
        slug,
        description,
        mainImage{
          asset->{
            _id,
            url
          }
        },
        theWhat,
        theWhy,
        theHow,
      }`;

  const result = await sanityClient.fetch(query);

  return result;
};

export const fetchAllPublications = async () => {
  let query = `*[_type == "publications"][0..50]| order(publishedAt desc) {
        title,
        slug,
        publishedAt,
        body,
         doi,
    pubmed,
        mainImage{
          asset->{
            _id,
            url
          }
        },
        "name": author->name,
        "authorImage": author->image,
        "authorBio": author->bio,
        "authorProjects": author->projects,
        "authorMail": author->mail,
      }`;

  const result = await sanityClient.fetch(query);

  return result;
};

export const fetchOnePublicationArticle = async (id) => {
  let query = `*[slug.current == $id]{
    "categories": categories[]->title,
        title,
        slug,
        mainImage{
          asset->{
            _id,
            url,
            title,
            altText,
            description
           }
         },
       body[]{
        ...,
        _type == "image" => {
          ...,
          asset->{
            _id,
            url,
            title,
            altText,
            description
          }
        }
       },
       publishedAt,
       "updatedAt": _updatedAt,
      "name": author->name,
      "authorImage": author->image,
      "authorBio": author->bio,
      "authorProjects": author->projects,
      "authorMail": author->mail,
     }`;

  const result = await sanityClient.fetch(query, { id });

  return result[0];
};
