import { Col, Row, Typography } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { fetchAllNews } from "../../../api/Api";
import { Carousel } from "../../../components/Carousel";
import Container from "../../../components/Container";
import { navItems } from "../../../components/nav/SideNav";
import SwipeTrack from "../../../components/SwipeTrack";
import { dateFormatter } from "../../../dateFormatter";
import Careers from "../../../images/career-bg_min.webp";
// import MedQuery from "../../../images/MedQuery.webp";
// import MLFactory from "../../../images/MLFactory_min.webp";
import Radiology from "../../../images/radiology-bg.webp";
import LandingCard from "./LandingCard";
import NeoMedSys from "../../../images/landing_illustration_3D.png";
const { Paragraph } = Typography;

const overlayGradients = {
  research:
    "linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%)",
  mlfactory: "linear-gradient(0deg, rgb(6, 70, 146) 0%, rgb(52, 140, 212) 60%)",
   products: "linear-gradient(0deg, rgb(68, 52, 108) 0%, rgb(69, 65, 218) 60%)",
  neomedsys: "linear-gradient(0deg, rgb(68, 52, 108) 0%, rgb(69, 65, 218) 60%) mix-blend-screen",
  careers: "linear-gradient(0deg, rgb(80, 68, 51) 0%, rgb(80, 170, 197) 60%)",
  partners:
    "linear-gradient(0deg, rgba(0,0,0,0) 20%,rgba(48, 167, 239, 0.8) 65%)",
};

const BodyContent = ({ brow, title }) => (
  <div
    style={{
      position: "absolute",
      top: "60px",
    }}
  >
    <Paragraph
      style={{
        textShadow: "none",
        paddingRight: "20px",
        marginBottom: "6px",
      }}
      className="text-white text-medium"
      ellipsis={{ rows: 5 }}
    >
      <span
        className="text-faded"
        style={{ fontWeight: "500", fontSize: ".8em" }}
      >
        {dateFormatter(brow)}
      </span>
      <br />
      {title}
    </Paragraph>
  </div>
);

const NewsArticleCarousel = () => {
  const {
    isLoading,
    error,
    data: allNews,
  } = useQuery("news", fetchAllNews, { refetchOnWindowFocus: false });

  if (isLoading || error) return null;

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",

          width: "200px",
          zIndex: 5,
        }}
        className="background-light rounded"
      >
        <Link to="/news">
          <div
            title="News room"
            style={{
              padding: "16px",
              zIndex: 3,
              position: "relative",
              height: "100%",
            }}
          >
            <div style={{ textShadow: "2px 2px 6px rgba(0,0,0,0.2)" }}>
              <div
                className="text-white text-medium"
                style={{ paddingRight: "25px" }}
              >
                News
              </div>
              <div
                style={{
                  position: "absolute",
                  right: "16px",
                  top: "20px",
                }}
                className="text-white"
              >
                {navItems
                  .filter((item) => item.title.includes("news"))
                  .map(({ title, icon }) => (
                    <div key={title}>{icon}</div>
                  ))}
              </div>
            </div>
          </div>
        </Link>
      </div>
      <Carousel
        className="rounded background-light landing-card-dimentions landing-card"
        effect="scroll"
        pauseOnHover
        autoplay={true}
        dotPosition={"right"}
        arrows={false}
        slidesToShow={3}
        slidesToScroll={1}
      >
        {allNews.slice(0, 4).map(({ slug, title, publishedAt }) => (
          <div key={slug.current}>
            <LandingCard
              header="News"
              link={`/news/article/${slug.current}`}
              gradient={overlayGradients.partners}
              showIcon={false}
              body={<BodyContent brow={publishedAt} title={title} />}
            />
          </div>
        ))}
      </Carousel>
    </>
  );
};

const landingCards = {
  publications: <NewsArticleCarousel />,
  research: (
    <LandingCard
      header="Research"
      img={
        "https://images.unsplash.com/photo-1581594355548-aab613ebdb69?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=997&q=80"
      }
      gradient={overlayGradients.research}
      link="research"
      actionBtnTxt="View research"
    />
  ),
  neomedsys: (
    <LandingCard
      header="NeoMedSys"
      img={NeoMedSys}    
      gradient={overlayGradients.neomedsys}
      link="https://neomedsys.io/" 
      target_value="_blank"
      />
  ),
  products: (
    <LandingCard
      header="Products"
      img={
       "https://d3lkc3n5th01x7.cloudfront.net/wp-content/uploads/2022/10/18123357/AI-in-web3-2.png"
      }     
       gradient={overlayGradients.medquery}
      link="products"
    />
  ),

  careers: (
    <LandingCard
      header="Careers"
      img={Careers}
      gradient={overlayGradients.careers}
      link="careers"
      actionBtnTxt="See jobs"
    />
  ),
  partners: (
    <LandingCard
      img={Radiology}
      gradient={overlayGradients.partners}
      header="Collaborating Partners"
      actionBtnTxt="View partners"
      link='#'
      linkid="/home#partners"
    />
  ),
};

export const LandingCardsLarge = () => (
  <Row
    gutter={[32, 32]}
    className="landing-cards-container"
    style={{
      position: "absolute",
      transform: "translateY(-45%)",
      zIndex: 1,
    }}
  >
    <Col lg={8} style={{ marginTop: "60px" }}>
      <Row gutter={[0, 16]}>
        <Col xs={24}>{landingCards.publications}</Col>
        <Col xs={24}>{landingCards.research}</Col>
      </Row>
    </Col>
     <Col lg={8}>
      <Row gutter={[0, 16]}>
        <Col xs={24}>{landingCards.neomedsys}</Col>
        <Col xs={24}>{landingCards.products}</Col>
      </Row>
    </Col>
    <Col lg={8} style={{ marginTop: "95px" }}>
      <Row gutter={[0, 16]}>
        <Col xs={24}>{landingCards.careers}</Col>
        <Col>{landingCards.partners}</Col>
      </Row>
    </Col>
  </Row>
);

export const LandingCardsMedium = () => (
  <Container style={{ padding: "0" }}>
    <Carousel
      style={{
        paddingBottom: "50px",
        paddingLeft: "24px",
        paddingRight: "24px",
      }}
      arrows={false}
      autoplaySpeed={5000}
      pauseOnHover
      slidesToShow={4}
      slidesToScroll={4}
      effect=""
      responsive={[
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
          },
        },
      ]}
    >
      {Object.entries(landingCards).map(([key, value]) => (
        <div key={key}>{value}</div>
      ))}
    </Carousel>
  </Container>
);

export const LandingCardsSmall = () => (
  <SwipeTrack height="279px">
    {Object.entries(landingCards)
      .slice(1)
      .map(([key, value]) => (
        <div key={key}>{value}</div>
      ))}
  </SwipeTrack>
);

BodyContent.propTypes = {
  brow: PropTypes.string,
  title: PropTypes.string,
};
